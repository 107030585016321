import * as React from "react"
import SubmissionForm from "@jukin/submission-form"
export default class IndexPage extends React.PureComponent {
  render() {
    return (
      <div className="container">
        <SubmissionForm
          site="The Pet Collective"
          riffUrl={process.env.GATSBY_RIFF_URL}
          submissionSource="petcollective"
          uploadPrefix={process.env.GATSBY_UPLOAD_PREFIX}
          tos="https://thepetcollective.com/policies/terms-of-service"
          mailchimpUrl={process.env.GATSBY_MAILCHIMP_URL}
          mailchimpListID={process.env.GATSBY_MAILCHIMP_LIST_ID}
          typeform={process.env.GARSBY_TYPEFORM}
          isNewsletterSignUpRequired={false}
        />
      </div>
    )
  }
}
